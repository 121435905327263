<template>
  <div>
    <v-row>
      <v-col cols="8" class="px-0">
        <p class="ma-0">
          <span class="secondary--text">{{ responsable }}</span>
          <span class="grey--text ml-2">{{ fechaHaceTiempo(fecha) }}</span>
        </p></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="8" class="pt-0 pb-0 mb-5 px-0 respuesta">
        <p>{{ respuesta }}</p>
        <div class="pb-3" v-if="nombre != null && ruta != null">
          <v-icon class="mr-2" color="secondary">mdi-file-pdf-box</v-icon>
          <a @click="descargarArchivo">{{ nombre }}</a>
        </div>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </div>
</template>
<script>
export default {
  name: "ConsultaRespuestaComponent",
  props: {
    id: {
      type: Number,
      required: true,
    },
    idConsulta: {
      type: Number,
      required: true,
    },
    responsable: {
      type: String,
      required: true,
    },
    fecha: {
      type: String,
      required: true,
    },
    respuesta: {
      type: String,
      required: true,
    },
    estado: {
      type: Number,
      required: true,
    },
    nombre: {
      type: String,
      required: false,
    },
    ruta: {
      type: String,
      required: false,
    },
  },
  methods: {
    async descargarArchivo() {
      const response = await this.services.Paac.descargarDocumentoConstulas(
        this.id,
        this.ruta
      );

      if (response.status == 200) {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.ruta;
        link.click();
      }
    },
  },
};
</script>
<style lang="scss">
.respuesta {
  border-bottom: 1px solid #e0e0e0 !important;
}
</style>
